import React, { useEffect, useContext, useRef, Fragment } from 'react';
import https from 'https';
import cookie from 'cookie';
import PropTypes from 'prop-types'
import dynamic from 'next/dynamic';

import { axios } from '@hotmart/request';
import Body from 'components/Body';
import HtmlHead from 'components/HtmlHead';

import { ProfileContext } from 'components/core/Provider/ProfileProvider';

import config from '../../../../env.config';
import Service from '../../../../src/@profile/service';
import Utils from '../../../../src/global/scripts/utils';
import { withTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { sendAnalyticsEvent } from '../../../../src/utils/datalayer';
import ErrorHandler from '../../../../src/global/scripts/errorHandler';
import { localStorageAddProductInformation } from '../../../../src/utils/localStorage';
import useSuperPixel from 'hooks/useSuperPixel';

import { getProductPrice } from 'utils/prices/';
import { STRUCTURED_DATA_TYPE } from 'utils/experiments';
import { featureSwitchKey } from 'utils/features/constants';
import RedesignedContent from 'components/@redesign/Content';
import { categories } from 'src/constants';

const ScriptLoader = dynamic(() => import('components/ScriptLoader'));
const StructuredData = dynamic(() => import('components/StructuredData'), {
  ssr: false
});
function Query(props) {
  const { t, query, ip, campaignData, userAgent, isMobileUserAgent, locale, pixel } = props;
  let { profile } = props;
  const { sck } = query;
  let off = query.off;
  const context = useContext(ProfileContext);
  const { opened } = context?.sidebar;
  const url = `${Utils.mountMarketplaceResultsPage(locale)}/${profile.product.slug}/${profile.product.producerReferenceCode}`;
  const isBlackNovember = Utils.isBlackNovember(profile.product, query);
  const productReviews = profile.showReviewsOnProductProfile
  ? profile?.customerReview
  : null;
  const isStructuredDataActive = profile.product?.format === categories.COURSES_ONLINE;

  // adiciona dados do produto no localStorage
  localStorageAddProductInformation(profile.product);

  const project = sck && sck === 'HOTMART_SITE' ? 'Marketplace' : 'Product';

  const headerRef = useRef(null);

  const hotmarter = profile.productOwner || profile.product.hotmarter;

  useEffect(() => {
    const { category, format, hotTopic } = profile.product;

    sendAnalyticsEvent({
      event: 'noninteraction',
      category: category,
      format: format,
      topification: hotTopic?.topification,
      topic: hotTopic?.topic,
    })

    sendAnalyticsEvent({
      event: 'noninteraction',
      event_category: `StructuredData:${STRUCTURED_DATA_TYPE.COURSE}`,
      event_action: `${isStructuredDataActive ? 'Enabled' : 'Disabled'}`,
      event_label: `Product:${profile.product?.productId}`
    });
  }, [])

  useEffect(() => {
    if (profile.product.offer && !off) {
      off = profile.product.offer;
    }
    profile.isBlackNovember = isBlackNovember;
    profile.productsPriceToggle = props?.productsPriceToggle;
    context.actions.setProfile({ ...profile, userAgent });

    if (!context.checkout?.data?.products) {
      new Promise((resolve, reject) => {
        resolve(getProductPrice(context, profile, query, ip));
      })
    }
  }, []);

  const { triggerPixel } = useSuperPixel(profile?.product);

  let browserTitle = profile.product.name;
  if (
    !profile.hiddenAuthorInformation &&
    profile.product &&
    hotmarter &&
    hotmarter.name
  ) {
    browserTitle = `${browserTitle} - ${hotmarter.name}`;
  }

  return (
    <Fragment>
      <ScriptLoader
        producerId={profile?.product?.hotmarter?.launcherAccountId}
        productId={profile?.product?.productId}
        productName={profile?.product?.name}
        pixel={pixel}
        onLoadSuperPixel={() => triggerPixel([hotmarter.ucode])}
      />
      {isStructuredDataActive && (
        <StructuredData
          product={profile?.product}
          checkout={context.checkout}
          reviews={productReviews}
        />
      )}
      <HtmlHead
        title={t('salesPage.browser_title', {
          format: t(profile.product.format),
          title: browserTitle
        })}
        description={t('salesPage.browser_description', {
          category: t(`subcategory.${profile.product.category}`)
        })}
        url={url}
        image={profile.product.avatar}
        video={profile.product.videoLink}
        locale={locale}
        category={profile.product.category}
        format={profile.product.format}
        cdn={`${config.envConfig.ASSETS_PREFIX ? config.envConfig.CDN : null}`}
      />
      <Body
        language={locale}
        profile={profile}
        showHeader={opened}
        project={project}
        headerRef={headerRef}
        slug={profile.product.slug}
        simplified={true}
        ip={ip}
        isMobile={isMobileUserAgent}
      >
        <RedesignedContent
          profile={profile}
          headerRef={headerRef}
          ip={ip}
          isMobile={isMobileUserAgent}
        />
      </Body>
    </Fragment>
  );
}

export const getServerSideProps = async ({ query, res, req, locale, locales, ...ctx }) => {
  let profile = {};
  let checkout = {};
  let cookieParsed = '';
  let cookies;
  let ip = '';
  let campaignData = {};
  let productsPriceToggle = false

  if (req) {
    ip = Utils.getRemoteAddr(req.headers['x-forwarded-for'] || req.connection.remoteAddress);
    cookies = cookie.parse(req.headers.cookie || '');
    axios.defaults.headers.common['x-forwarded-for'] = ip;
    if (query.origin === 'backoffice') {
      cookieParsed = Utils.encodeToken(cookies.hmBackofficeToken || '');
    } else {
      cookieParsed = Utils.encodeToken(cookies.hmVlcIntegration || '');
    }
  }

  if (cookieParsed) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${cookieParsed}`;
  }

  try {
    const featureSwitch = await Service.getFeatureSwitch();
    productsPriceToggle = featureSwitch[featureSwitchKey.PRODUCT_PRICES_GATEWAY] || false;
  } catch (e) {
    console.error(e)
  }

  try {
    if (query.forceError) {
      await Service.getErrorMock(query.forceError);
    } else {
      if (req && config.currentEnv !== 'development') {
        // At request level pass https agent to axios request
        const agent = new https.Agent({
          rejectUnauthorized: false
        });
        profile = await Service.getProfileInternal(query, agent);
      } else {
        profile = await Service.getProfile(query);
      }
    }
  } catch (e) {
    if (e?.response?.status === 401) {
      return ErrorHandler.unauthorized(res, req, e, locale)
    } else if (e?.response?.status === 402 || e?.response?.status === 403) {
      return ErrorHandler.forbidden(res, req, e, locale)
    } else if (e?.response?.status === 404) {
      return ErrorHandler.notFound(res, req, e, locale);
    } else {
      return ErrorHandler.checkoutRecover(res, req, e, query, locale);
    }
  }

  query = Utils.objectKeysToLowerCase(query);

  let userAgent;
  if (req) { // if you are on the server and you get a 'req' property from your context
    userAgent = req.headers['user-agent'] // get the user-agent from the headers
  } else {
    userAgent = navigator.userAgent // if you are on the client you can access the navigator from the window object
  }

  let isMobileUserAgent = Utils.isMobileDevice(userAgent);

  const headers = req && req?.headers
  const acceptLanguage = headers && headers['accept-language'] ? Utils.getLanguageSymbol(headers['accept-language']) : '';
  const isLanguageOnQuery = query && query.lng;

  return {
    props: {
      ...(await serverSideTranslations(isLanguageOnQuery || locale, ['common'])),
      profile,
      pixel: profile?.pixel || null,
      checkout,
      cookieParsed,
      query,
      ip,
      campaignData,
      userAgent,
      isMobileUserAgent,
      locale,
      locales,
      acceptLanguage,
      productsPriceToggle
    }
  };
};

Query.propTypes = {
  t: PropTypes.func,
  profile: PropTypes.object,
  cookieParsed: PropTypes.string,
  query: PropTypes.object,
  ip: PropTypes.string,
  campaignData: PropTypes.object,
  checkout: PropTypes.object,
  locales: PropTypes.array,
  locale: PropTypes.string,
  acceptLanguage: PropTypes.string
}

export default withTranslation()(Query);
